import React from "react";
import {
    connect,
    GLForm,
    GLFormComponentProps,
    GLGlobal,
    GLRouteComponentProps,
    MessageHelper,
    NotificationType,
    ResourceType,
    Role,
    RoleName,
    StateType
} from "gl-commonui";
import { flexGridColumns } from "./component/helper";
import { CONSTS, fmtMsg, TYPES } from "@app/util";
import { useService } from "@app/hooks";
import { DashboardLocale, GSAdminLocale } from "@app/locales/localeid";
import { ChildrenList } from "./component/children-list";
import { SchoolClassList } from "./component/school-class-list";
import { setBreadcrumb } from "@app/states/admin/resource";
import { Loading } from "@app/components/survey";
import { IResourceService } from "@app/service/resources";
import "./permissions.less";
import { RoleHelper } from "@app/util/roleHelper";
import { Modal } from "antd-min";
interface UserPermissionsProps extends GLRouteComponentProps {
    setBreadcrumb: (state: any) => void;
    langLoaded: string;
    userRoles: string[];
    userId: string;
    removeResource: (role: Role, d: RemoveResourceData, gridData?: UserSupportResource[], trainingMangerGrid?: UserSupportResource[]) => void;
    resourceRemoved: ResourceRemovedCallbackData;
    readonly: boolean;
    setResource: (r: any) => void;
    reloadUserInfo: () => void;
    updateTableLayout: (role, resourceid, isRemoveRegion?: boolean) => void;
}

export interface ResourceRemovedCallbackData {
    role: Role;
    resourceId: string;
    isRemoveRegion?: boolean;
}
export interface RemoveResourceData {
    id: string;
    name: string;
    resourceType?: ResourceType;
    schoolId?: string;
    regionId?: string;
}

export interface UserSupportResource {
    regionId: string;
    region: string;
    schoolId?: string;
    school?: string;
    campusId?: string;
    campus?: string;
    schoolClassId?: string;
    schoolClass?: string;
    disabled?: boolean;
}

interface UserSupportResources {
    SystemAdmin?: UserSupportResource[];
    RegionAdmin?: UserSupportResource[];
    AccountManager?: UserSupportResource[];
    Trainer?: UserSupportResource[];
    TrainingManager?: UserSupportResource[];
    SchoolAdmin?: UserSupportResource[];
    CampusAdmin?: UserSupportResource[];
    Teacher?: UserSupportResource[];
}

let trainerData: UserSupportResource[] = [];
let trainingManagerData: UserSupportResource[] = [];


const UserPermissionChild = (props: UserPermissionsProps & GLFormComponentProps) => {
    const [loading, setLoading] = React.useState(true);
    const [loadingOnlyTeacher, setLoadingOnlyTeacher] = React.useState(false);
    const [data, setData] = React.useState<UserSupportResources>();
    const service = useService<IResourceService>(TYPES.IResourceService);
    const { userRoles, userId, removeResource, resourceRemoved, readonly } = props;
    React.useEffect(() => {
        window["removeUserResource"] = removeUserResource;
        loadResources();

        return () => {
            delete window["removeUserResource"];
        }
    }, []);

    React.useEffect(() => {
        if (resourceRemoved && Object.keys(resourceRemoved).length > 0) {
            // remove the resource from the data
            let dat: UserSupportResources = JSON.parse(JSON.stringify(data));
            if (resourceRemoved.role === Role.SchoolAdmin) {
                dat.SchoolAdmin = data.SchoolAdmin.filter(d => d.schoolId != resourceRemoved.resourceId);
                setData(dat);
            }
            else if (resourceRemoved.role === Role.RegionAdmin) {
                dat.RegionAdmin = data.RegionAdmin.filter(d => d.regionId != resourceRemoved.resourceId);
                setData(dat);
            }
            else if (resourceRemoved.role === Role.AccountManager) {
                dat.AccountManager = data.AccountManager.filter(d => d.schoolId != resourceRemoved.resourceId);
                setData(dat);
            }
            else if (resourceRemoved.role === Role.CampusAdmin) {
                dat.CampusAdmin = data.CampusAdmin.filter(d => d.campusId != resourceRemoved.resourceId);
                setData(dat);
            }
            else if (resourceRemoved.role === Role.Teacher) {
                // Refresh teacher list.
                setLoadingOnlyTeacher(true);
                loadResources(true);
            }
            else if (resourceRemoved.role === Role.Trainer) {
                // when school coach removed
                if (!resourceRemoved.isRemoveRegion) {
                    data.Trainer.map(coach => {
                        if (coach.schoolId == resourceRemoved.resourceId) {
                            coach.schoolId = CONSTS.EmptyGuid;
                            coach.school = "";
                        }
                    });
                    dat.Trainer = [...data.Trainer];
                } else {
                    // get regionId of trainnig manager to be deleted
                    const regionCoach = data.Trainer.filter(d => d.schoolId == resourceRemoved.resourceId || d.regionId == resourceRemoved.resourceId);

                    // remove school coach completely or remove region coach
                    dat.Trainer = data.Trainer.filter(d => (d.schoolId != CONSTS.EmptyGuid && d.schoolId != resourceRemoved.resourceId) || (d.schoolId == CONSTS.EmptyGuid && d.regionId != resourceRemoved.resourceId));

                    // remove training manager in case of completely removal of coach from school or region coach removal
                    if (regionCoach && regionCoach.length == 1) {
                        const regionIdToDelete = regionCoach[0].regionId;
                        dat.TrainingManager = data.TrainingManager.filter(d => d.regionId != regionIdToDelete);
                    }
                }
                setData(dat);
                trainerData = dat.Trainer;
                trainingManagerData = dat.TrainingManager;

            }
            else if (resourceRemoved.role === Role.TrainingManager) {
                dat.TrainingManager = data.TrainingManager.filter(d => d.regionId != resourceRemoved.resourceId);
                setData(dat);
                trainingManagerData = dat.TrainingManager;
            }
        }
    }, [resourceRemoved])


    const loadResources = (teacherOnly: boolean = false) => {
        let roleIds = userRoles.map(role => Role[role]);
        if (teacherOnly) {
            roleIds = [Role[RoleName.teacher]];
        }
        service
            .getUserResourcesForSupport({
                userId: userId,
                userRoles: roleIds,
                isFromEditUser: true
            })
            .then((assoData: UserSupportResources) => {
                props.setResource(assoData);
                if (teacherOnly) {
                    let dataCopy = { ...data };
                    dataCopy.Teacher = assoData.Teacher;
                    setData(dataCopy);
                    setLoadingOnlyTeacher(false);
                } else {
                    setData(assoData);
                    trainerData = assoData.Trainer;
                    trainingManagerData = assoData.TrainingManager;
                    setLoading(false);
                }
            }).catch(() => {
                loading && setLoading(false);
                loadingOnlyTeacher && setLoadingOnlyTeacher(false)
            });
    };

    const showAllRegions = (): boolean => {
        if (userRoles.indexOf(RoleName.systemAdmin) > -1 ||
            userRoles.indexOf(RoleName.globalHead) > -1 ||
            userRoles.indexOf(RoleName.trainingAdmin) > -1) {
            return true;
        }
        return false;
    }

    const shouldShowResource = (role: RoleName) => {
        if (userRoles.indexOf(role) > -1) {
            return true;
        }
        return false;
    }

    const removeUserResource = (role: Role, removeData: RemoveResourceData) => {
        let gridData: UserSupportResource[] = [];
        let trainingMangerGrid = [];
        if (role == Role.Trainer) {
            gridData = trainerData;
            trainingMangerGrid = trainingManagerData;
        }

        removeResource(role, { ...removeData, name: unescape(removeData.name) }, gridData, trainingMangerGrid);
    }

    const onRoleRemove = (role: Role) => {
        const removeRole = () => {
            service.removeUserRole(role, userId).then(() => {
                MessageHelper.Message(NotificationType.Success, fmtMsg({ id: GSAdminLocale.UsersRoleRemoveSucc }));
                props.reloadUserInfo();
                setLoading(true);
                loadResources();
            }).catch(() => {
                MessageHelper.Message(NotificationType.Failed, fmtMsg({ id: GSAdminLocale.UsersRoleRemoveFail }));
            });
        }

        // show warning while removing
        let warningMsg = "";
        if (userRoles.length === 1) {
            // if user have one role
            warningMsg = fmtMsg({ id: GSAdminLocale.UsersRoleRemoveLooseWarn });
        } else {
            warningMsg = fmtMsg({ id: GSAdminLocale.UsersRoleRemoveLossWarn })
        }
        Modal.confirm({
            onOk: removeRole,
            okText: fmtMsg({ id: GSAdminLocale.UsersRoleRemoveConfirmYes }),
            cancelText: fmtMsg({ id: GSAdminLocale.UsersRoleRemoveConfirmNo }),
            title: warningMsg
        });
    }

    const headingTextSuperUser = (): { roleNames: string[], roles: Role[] } => {
        const roles: Role[] = [];
        const roleNames: string[] = [];
        if (showAllRegions()) {
            if (userRoles.indexOf(RoleName.systemAdmin) > -1) {
                roleNames.push(fmtMsg({ id: DashboardLocale.LandingTabSystemAdminText }));
                roles.push(Role.SystemAdmin);
            }
            if (userRoles.indexOf(RoleName.globalHead) > -1) {
                roleNames.push(fmtMsg({ id: DashboardLocale.LandingTabGlobalHeadText }));
                roles.push(Role.GlobalHead);
            }
            if (userRoles.indexOf(RoleName.trainingAdmin) > -1) {
                roleNames.push(fmtMsg({ id: DashboardLocale.LandingTabTrainingAdminText }));
                roles.push(Role.TrainingAdmin);
            }
            return { roleNames, roles };
        }
    }

    const showTraingingManagerList = (): boolean => {
        let roles = GLGlobal.loginInfo().profile.roles;
        return roles && roles.indexOf(RoleName.systemAdmin) > -1
            || roles.indexOf(RoleName.globalHead) > -1
            || roles.indexOf(RoleName.trainingAdmin) > -1
            || roles.indexOf(RoleName.regionAdmin) > -1;
    }

    const canDeleteTrainingManager = (): boolean => {
        if (!RoleHelper.isCurrentUserRolesGreaterThan(props.userRoles)) {
            return false;
        }
        const roles = GLGlobal.loginInfo().profile.roles;
        return roles && roles.indexOf(RoleName.systemAdmin) > -1
            || roles.indexOf(RoleName.trainingAdmin) > -1
            || roles.indexOf(RoleName.regionAdmin) > -1;
    }

    const canDeleteTrainer = (): boolean => {
        const roles = GLGlobal.loginInfo().profile.roles;
        if (!roles) {
            return false;
        }

        if (!RoleHelper.isCurrentUserRolesGreaterThan(props.userRoles)) {
            return false;
        }

        const isSuper = roles && (roles.indexOf(RoleName.systemAdmin) > -1
            || roles.indexOf(RoleName.trainingAdmin) > -1
            || roles.indexOf(RoleName.regionAdmin) > -1);

        return isSuper || roles.indexOf(RoleName.globalHead) < 0;
    }
    const readonlyLocal = typeof readonly === "boolean" ? readonly : true;
    const superUserHeading = headingTextSuperUser();


    const getTeacherColumns = () => {
        let teacherCloumns: any = flexGridColumns.teacher;

        if (RoleHelper.getMaxRoleOfCurrentUser() === RoleName.systemAdmin) {
            teacherCloumns = [...teacherCloumns];
        }

        if (!readonlyLocal && RoleHelper.isCurrentUserRolesGreaterThan(props.userRoles)) {
            teacherCloumns = [...teacherCloumns, ...flexGridColumns.removeColumn];
        }

        return teacherCloumns;
    }

    return (<>
        <Loading visible={loading} />
        {!loading && props.userRoles && data && (
            <>
                {showAllRegions() &&
                    <SchoolClassList list={data.SystemAdmin} flexGridColumns={flexGridColumns.systemAdmin}
                        extra={
                            {
                                heading: superUserHeading.roleNames,
                                superRoles: superUserHeading.roles,
                                role: superUserHeading.roles[0]
                            }
                        }
                        onRoleRemove={onRoleRemove} />
                }
                {shouldShowResource(RoleName.regionAdmin) && <SchoolClassList list={data.RegionAdmin}
                    flexGridColumns={[...flexGridColumns.regionAdmin, ...(!readonlyLocal && RoleHelper.isCurrentUserRolesGreaterThan(props.userRoles) ? flexGridColumns.removeColumn : [])]}
                    extra={{
                        role: Role.RegionAdmin,
                        nameIndex: "regionEnglishName",
                        resourceType: ResourceType.Region,
                        resourceIdIndex: "regionId",
                        heading: fmtMsg({ id: DashboardLocale.LandingTabRegionAdminText })
                    }}
                    onRoleRemove={onRoleRemove}
                />}
                {shouldShowResource(RoleName.accountManager) && <SchoolClassList list={data.AccountManager}
                    flexGridColumns={[...flexGridColumns.schoolAdminAccountManager, ...(!readonlyLocal && RoleHelper.isCurrentUserRolesGreaterThan(props.userRoles) ? flexGridColumns.removeColumn : [])]}
                    extra={{
                        role: Role.AccountManager,
                        nameIndex: "school",
                        resourceType: ResourceType.School,
                        resourceIdIndex: "schoolId",
                        heading: fmtMsg({ id: DashboardLocale.LandingTabAccountManagerText })
                    }}
                    onRoleRemove={onRoleRemove}
                />}
                {shouldShowResource(RoleName.schoolAdmin) && <SchoolClassList list={data.SchoolAdmin}
                    flexGridColumns={[...flexGridColumns.schoolAdminAccountManager, ...(!readonlyLocal && RoleHelper.isCurrentUserRolesGreaterThan(props.userRoles) ? flexGridColumns.removeColumn : [])]}
                    extra={{
                        role: Role.SchoolAdmin,
                        nameIndex: "school",
                        resourceType: ResourceType.School,
                        resourceIdIndex: "schoolId",
                        heading: fmtMsg({ id: DashboardLocale.LandingTabSchoolAdminText })
                    }}
                    onRoleRemove={onRoleRemove}
                />}
                {shouldShowResource(RoleName.trainer) && <SchoolClassList list={data.Trainer}
                    flexGridColumns={[...flexGridColumns.schoolAdminAccountManager, ...(!readonlyLocal && canDeleteTrainer() ? flexGridColumns.removeColumn : [])]}
                    extra={{
                        role: Role.Trainer,
                        nameIndex: "school",
                        resourceType: ResourceType.School,
                        resourceIdIndex: "schoolId",
                        heading: fmtMsg({ id: DashboardLocale.LandingTabTrainerAdminText })
                    }}
                    onRoleRemove={onRoleRemove}
                />}
                {shouldShowResource(RoleName.trainingManager) && showTraingingManagerList() && <SchoolClassList list={data.TrainingManager}
                    flexGridColumns={[...flexGridColumns.regionAdmin, ...(!readonlyLocal && canDeleteTrainingManager() ? flexGridColumns.removeColumn : [])]}
                    extra={{
                        role: Role.TrainingManager,
                        nameIndex: "region",
                        resourceType: ResourceType.Region,
                        resourceIdIndex: "regionId",
                        heading: fmtMsg({ id: DashboardLocale.LandingTabTrainingManagerText })
                    }}
                    onRoleRemove={onRoleRemove}
                />}
                {shouldShowResource(RoleName.campusAdmin) && <SchoolClassList list={data.CampusAdmin}
                    flexGridColumns={[...flexGridColumns.campusAdmin, ...(!readonlyLocal && RoleHelper.isCurrentUserRolesGreaterThan(props.userRoles) ? flexGridColumns.removeColumn : [])]}
                    extra={{
                        role: Role.CampusAdmin,
                        nameIndex: "campus",
                        resourceType: ResourceType.Campus,
                        resourceIdIndex: "campusId",
                        heading: fmtMsg({ id: DashboardLocale.LandingTabCampusAdminText })
                    }}
                    onRoleRemove={onRoleRemove}
                />}
                {shouldShowResource(RoleName.teacher) && <SchoolClassList list={data.Teacher} loading={loadingOnlyTeacher}
                    flexGridColumns={getTeacherColumns()}
                    extra={{
                        role: Role.Teacher,
                        nameIndex: "schoolClass",
                        resourceType: ResourceType.SchoolClass,
                        resourceIdIndex: "schoolClassId",
                        heading: fmtMsg({ id: DashboardLocale.LandingTabTeacherText })
                    }}
                    onRoleRemove={onRoleRemove}
                />}
                {shouldShowResource(RoleName.contentAdmin) && <SchoolClassList list={[]} loading={false}
                    extra={{
                        role: Role.ContentAdmin,
                        heading: fmtMsg({ id: GSAdminLocale.UsersContentAdminRole }),
                        hideGrid: true
                    }}
                    onRoleRemove={onRoleRemove}
                />}
            </>
        )}
        {shouldShowResource(RoleName.parent) && <ChildrenList isFromEditUser={true} parentId={userId} parentLoading={loading} />}
    </>);
};

export const UserPermissions = connect(
    ({ intl: { langLoaded } }: StateType) => ({ langLoaded }),
    { setBreadcrumb })(GLForm.create()(UserPermissionChild));
