import * as React from "react";
import { GLForm, GLFormComponentProps, FormHelper, FormItemsLayout, connect, StateType } from "gl-commonui";
import { Icon, message, Modal, Input, Tooltip, Row, Col } from "antd-min";
import { useService } from "@app/hooks";
import { GetUserByIdResponseModel, IAccountService } from "@app/service/admin/accountservice";
import { TYPES, fmtMsg } from "@app/util";
import { GSAdminLocale } from "@app/locales/localeid";
import { ResetPassword } from "@app/components/admin/common/reset-password";
import "./userinfo.less";
import {RoleHelper} from "@app/util/roleHelper";

interface UserInfoProps extends GLFormComponentProps {
    userId: string;
	user: GetUserByIdResponseModel;
    langLoaded: string;
    readonly: boolean;
}

const UserInfoDisplay = (props: UserInfoProps) => {
    const accountService = useService<IAccountService>(TYPES.IAccountService);
    const [showEditEmailModal, setShowEditEmailModal] = React.useState(false);
    const [changeEmailLoading, setChangeEmailLoading] = React.useState(false);
    const { userId, langLoaded, form, readonly, user } = props;

    const onEditUserEmailClick = () => {
        setShowEditEmailModal(true);
    }

    const asyncEmailValidator = (rule, value, callback) => {
        return accountService.validateEmail({ email: value })
            .then(({ exist }) => {
                if (exist) {
                    callback(fmtMsg({ id: GSAdminLocale.EmailAlreadyExists }));
                } else {
                    callback();
                }
            }).catch(() => {
                callback();
            });
    }

    const emailValidator = (rule, value, callback) => {
        value = value ? value.trim() : "";
        if (!value) {
            callback(new Error(fmtMsg({ id: GSAdminLocale.ChangeEmailRequired })));
            return;
        }
        if (value === user.email) {
            callback(new Error(fmtMsg({ id: GSAdminLocale.ChangeEmailNewEmail })));
            return;
        } else {
            const { validator } = FormHelper.ruleForEmail(langLoaded);
            validator(rule, value, callback);
            return;
        }
    }

    const renderEditEmailModal = () => (<Modal
        confirmLoading={changeEmailLoading}
        visible={showEditEmailModal}
        destroyOnClose
        okText={fmtMsg({ id: GSAdminLocale.ButtonSave })}
        cancelText={fmtMsg({ id: GSAdminLocale.ButtonCancel })}
        title={fmtMsg({ id: GSAdminLocale.SupportChangeEmail })}
        className="uinfo__modal portal-theme"
        width="400px"
        onCancel={() => {
            setShowEditEmailModal(false);
        }}
        okButtonDisabled={!!form.getFieldsError()["email"]}
        onOk={() => {
            form.validateFields((error, values) => {
                if (error) {
                    return;
                }

                const { email } = values;
                setChangeEmailLoading(true);
                accountService.editEmail({
                    userId,
                    email
                }).then(() => {
                    message.success(fmtMsg({ id: GSAdminLocale.UserUpdateSuccess }));
                    setChangeEmailLoading(false);
                    setShowEditEmailModal(false);
                }).catch(() => {
                    setChangeEmailLoading(false);
                    message.error(fmtMsg({ id: GSAdminLocale.UserUpdateError }));
                });
            });
        }}
    >
        <GLForm form={form}  >
            <FormItemsLayout colTotal={1}>
                {FormHelper.renderFormItem({
                    decoratorOptions: {
                        rules: [
                            { validator: emailValidator },
                            { validator: asyncEmailValidator }
                        ]
                    },
                    form,
                    localeId: GSAdminLocale.SupportNewEmailLabel,
                    fieldName: "email",
                    formControl: <Input />,
                    initialValue: user ? user.email : null
                })}
            </FormItemsLayout>
        </GLForm>
    </Modal>)
    return (
        <>
            <Row className="uinfo" type="flex">
                <Col className="uinfo__email">
                    <Row>
                        <Col md={6} sm={6} lg={4} className="uinfo__label">
                            {fmtMsg({ id: GSAdminLocale.SupportUserEmail })}
                        </Col>
                        <Col md={18} xs={18}>
                            <span>{user && user.email}</span>
                            {!readonly && user && user.email && RoleHelper.isCurrentUserRolesGreaterThan(user.roles) && (
                                <span className="uinfo__editemail">
                                    <Tooltip
                                        title={fmtMsg({
                                            id: GSAdminLocale.SupportChangeEmail
                                        })}
                                    >
                                        <a onClick={onEditUserEmailClick}>
                                            <Icon type="edit" />
                                        </a>
                                    </Tooltip>
                                    <span className="uinfo__resetpassword">
                                        <ResetPassword userId={userId}></ResetPassword>
                                    </span>
                                </span>
                            )}
                        </Col>
                    </Row>
                </Col>
            </Row>
            {user && RoleHelper.isCurrentUserRolesGreaterThan(user.roles) && renderEditEmailModal()}
        </>
    );
}

export const UserInfo = connect(
    ({ intl: { langLoaded } }: StateType) => ({ langLoaded }))(GLForm.create()(UserInfoDisplay));
