import React, {useRef, useState} from "react";
import {
    GLGlobal,
    GLRouteComponentProps,
    GLUtil,
    LanguageDateFormat,
    MessageHelper,
    NotificationType,
    withRouter
} from "gl-commonui";
import {IParentService, ParentChildModel} from "@app/service/school/parent";
import {IStudentService} from "@app/service/school/student";
import {CONSTS, ContextHelper, DateHelper, fmtMsg, GSAdminAction, TYPES, useWrap} from "@app/util/index";
import {GSAdminLocale, SchoolLocale} from "@app/locales/localeid";
import {useService} from "@app/hooks";
import {PageHeader, WijmoGrid} from "@app/components";
import {AllowMerging, CellType, FlexGrid, GroupRow, SelectionMode} from "wijmo/wijmo.grid";
import {PathConfig} from "@app/config/pathconfig";
import {CloseIcon} from "./helper";
import {message, Modal} from "antd";
import moment from 'moment';
import {CustomGridEditor} from './custom-grid-editor';
import * as wjcInput from 'wijmo/wijmo.input';
import './children-list.less';

const { Grid, Column } = WijmoGrid;

interface ChildrenListProps extends GLRouteComponentProps {
    parentId: string;
    parentLoading?: boolean;
    isFromEditUser: boolean;
}

enum LinkType {
    name,
    region,
    school,
    campus,
    class
}


const ChildrenListComponent = (props: ChildrenListProps) => {
    const parentService = useService<IParentService>(TYPES.IParentService);
    const studentService = useService<IStudentService>(TYPES.IStudentService);
    const [wijmoData, setWijmoData] = React.useState<ParentChildModel[]>([]);
    const [loading, setLoading] = React.useState(true);
    const [gracePeriodEndDateIsEditing, setGracePeriodEndDateIsEditing] = useState(false);
    const defaultEditingRow = -1;
    const currentEditingRowRef = useRef(defaultEditingRow);
    const gracePeriodEndDateIsEditingRef = useRef(false);
    const [leaveDateIsEditing, setLeaveDateIsEditing] = useState(false);
    const leaveDateIsEditingRef = useRef(false);
    const [unitIsEditing, setUnitIsEditing] = useState(false);
    const unitIsEditingRef = useRef(false);
    const wijmoGridRef = useRef(null);
    const needSaveRef = useRef(false);
    const [miniPeriodDate, setMiniPeriodDate] = useState(null);
    const [maxPeriodDate, setMaxPeriodDate] = useState(null);
    const originalData = useRef<ParentChildModel[]>([]);
    const pageSize = 10;
    const gracePeriodEndDateColumnName = "gracePeriodEndDate";
    const gracePeriodEndDateActionColumnName = "gracePeriodEndDateAction";
    const leaveDateColumnName = "leaveDate";
    const leaveDateActionColumnName = "leaveDateAction";
    const unitColumnName = "unit";
    const unitActionColumnName = "unitAction";
    const editSVG = '<svg viewBox="64 64 896 896" class="" data-icon="edit" width="1em" height="1em" fill="currentColor" aria-hidden="true" focusable="false"><path d="M257.7 752c2 0 4-.2 6-.5L431.9 722c2-.4 3.9-1.3 5.3-2.8l423.9-423.9a9.96 9.96 0 0 0 0-14.1L694.9 114.9c-1.9-1.9-4.4-2.9-7.1-2.9s-5.2 1-7.1 2.9L256.8 538.8c-1.5 1.5-2.4 3.3-2.8 5.3l-29.5 168.2a33.5 33.5 0 0 0 9.4 29.8c6.6 6.4 14.9 9.9 23.8 9.9zm67.4-174.4L687.8 215l73.3 73.3-362.7 362.6-88.9 15.7 15.6-89zM880 836H144c-17.7 0-32 14.3-32 32v36c0 4.4 3.6 8 8 8h784c4.4 0 8-3.6 8-8v-36c0-17.7-14.3-32-32-32z"></path></svg>';
    const editButton = '<a class="leave-date edit"><i aria-label="icon: edit" class="anticon anticon-edit"><svg viewBox="64 64 896 896" class="" data-icon="edit" width="1em" height="1em" fill="currentColor" aria-hidden="true" focusable="false"><path d="M257.7 752c2 0 4-.2 6-.5L431.9 722c2-.4 3.9-1.3 5.3-2.8l423.9-423.9a9.96 9.96 0 0 0 0-14.1L694.9 114.9c-1.9-1.9-4.4-2.9-7.1-2.9s-5.2 1-7.1 2.9L256.8 538.8c-1.5 1.5-2.4 3.3-2.8 5.3l-29.5 168.2a33.5 33.5 0 0 0 9.4 29.8c6.6 6.4 14.9 9.9 23.8 9.9zm67.4-174.4L687.8 215l73.3 73.3-362.7 362.6-88.9 15.7 15.6-89zM880 836H144c-17.7 0-32 14.3-32 32v36c0 4.4 3.6 8 8 8h784c4.4 0 8-3.6 8-8v-36c0-17.7-14.3-32-32-32z"></path></svg></i></a>';
    const okSVG = '<svg viewBox="64 64 896 896" class="" data-icon="check" width="1em" height="1em" fill="currentColor" aria-hidden="true" focusable="false"><path d="M912 190h-69.9c-9.8 0-19.1 4.5-25.1 12.2L404.7 724.5 207 474a32 32 0 0 0-25.1-12.2H112c-6.7 0-10.4 7.7-6.3 12.9l273.9 347c12.8 16.2 37.4 16.2 50.3 0l488.4-618.9c4.1-5.1.4-12.8-6.3-12.8z"></path></svg>';
    const okButton = '<a class="leave-date ok"><i aria-label="icon: check" class="anticon anticon-check"><svg viewBox="64 64 896 896" class="" data-icon="check" width="1em" height="1em" fill="currentColor" aria-hidden="true" focusable="false"><path d="M912 190h-69.9c-9.8 0-19.1 4.5-25.1 12.2L404.7 724.5 207 474a32 32 0 0 0-25.1-12.2H112c-6.7 0-10.4 7.7-6.3 12.9l273.9 347c12.8 16.2 37.4 16.2 50.3 0l488.4-618.9c4.1-5.1.4-12.8-6.3-12.8z"></path></svg></i></a>';
    const cancelSVG = '<svg viewBox="64 64 896 896" class="" data-icon="close" width="1em" height="1em" fill="currentColor" aria-hidden="true" focusable="false"><path d="M563.8 512l262.5-312.9c4.4-5.2.7-13.1-6.1-13.1h-79.8c-4.7 0-9.2 2.1-12.3 5.7L511.6 449.8 295.1 191.7c-3-3.6-7.5-5.7-12.3-5.7H203c-6.8 0-10.5 7.9-6.1 13.1L459.4 512 196.9 824.9A7.95 7.95 0 0 0 203 838h79.8c4.7 0 9.2-2.1 12.3-5.7l216.5-258.1 216.5 258.1c3 3.6 7.5 5.7 12.3 5.7h79.8c6.8 0 10.5-7.9 6.1-13.1L563.8 512z"></path></svg>';
    const cancelButton = '<a class="leave-date cancel"><i aria-label="icon: close" class="anticon anticon-close"><svg viewBox="64 64 896 896" class="" data-icon="close" width="1em" height="1em" fill="currentColor" aria-hidden="true" focusable="false"><path d="M563.8 512l262.5-312.9c4.4-5.2.7-13.1-6.1-13.1h-79.8c-4.7 0-9.2 2.1-12.3 5.7L511.6 449.8 295.1 191.7c-3-3.6-7.5-5.7-12.3-5.7H203c-6.8 0-10.5 7.9-6.1 13.1L459.4 512 196.9 824.9A7.95 7.95 0 0 0 203 838h79.8c4.7 0 9.2-2.1 12.3-5.7l216.5-258.1 216.5 258.1c3 3.6 7.5 5.7 12.3 5.7h79.8c6.8 0 10.5-7.9 6.1-13.1L563.8 512z"></path></svg></i></a>';
    const dateEditorFormat = "YYYY-MM-DD";
    const maxLeaveDate = moment().format(dateEditorFormat);

    React.useEffect(() => {
        loadChildrenList();
    }, []);

    const loadChildrenList = () => {
        parentService.getStudentsByParent({ parentId: props.parentId, isFromEditUser: props.isFromEditUser }).then((data: ParentChildModel[]) => {
            data.forEach((data: ParentChildModel) => {
                data.registrationDate = DateHelper.toLocalStringFromUTC(data.registrationDate);
                data.gracePeriodEndDate = DateHelper.toLocalStringFromUTC(data.gracePeriodEndDate);
                data.gracePeriodEndDateOrigin = data.gracePeriodEndDate;
                data.leaveDate = data.leaveDate ? DateHelper.toLocalStringFromUTC(data.leaveDate) : null;
                data.leaveDateOrigin = data.leaveDate;
                data.extendSubscriptionLeaveDate = DateHelper.toLocalStringFromUTC(data.extendSubscriptionLeaveDate);
            });
            setLoading(false);
            originalData.current = data;
            setCollection(data);
        });
    };

    const setCollection = (data: ParentChildModel[]): void => {
        if (!data) {
            data = [];
        }
        setWijmoData(data);
    };

    const getLinkString = (dataForFunction: {
        regionId: string,
        schoolId: string;
        campusId: string;
        classId: string;
        childId: string;
    }, linkText: string, type: LinkType) => {
        return dataForFunction.regionId && dataForFunction.schoolId ? `<a href="${getHref({ ...dataForFunction, type })}">${linkText}</a>` : linkText;
    }

    const renderUnlinkChild = () => {
        return `<span class="remove_icon--pointer">${CloseIcon}</span>`
    }

    const unlinkChild = useWrap((childId: string, childName: string) => {
        const onOk = () => {
            return new Promise((resolve, reject) => {
                parentService.unlinkChild({ childId })
                    .then(() => {
                        originalData.current = originalData.current.filter(child => child.id != childId);
                        setCollection(originalData.current);
                        resolve();
                        message.success(fmtMsg({ id: GSAdminLocale.SupportChildRemoved }));
                    }).catch(() => {
                    resolve();
                });
            })
        }

        Modal.confirm({
            onOk,
            title: fmtMsg({ id: GSAdminLocale.SupportChildRemoveConfirm }, { childName: childName.trim() })
        });
    });

    const initGrid = (grid: FlexGrid) => {
        wijmoGridRef.current = grid;
        initGridColumnHeader(grid);
        initCustomGridEditors(grid);
        initGridHandler(grid);
    }

    const initGridColumnHeader = (grid: FlexGrid) => {
        const panel = grid.columnHeaders;
        panel.rows[0].allowMerging = true;
    }

    const initGridHandler = (grid: FlexGrid) => {
        grid.addEventListener(grid.hostElement, "click", handleEditableColumnAction);
        grid.cellEditEnding.addHandler((s, e) => {
            e.cancel = gracePeriodEndDateIsEditingRef.current || leaveDateIsEditingRef.current || unitIsEditingRef.current;
            e.data = needSaveRef.current;
            e.cancel && endEdit(false, e.row, e.col);
        });
    }

    const startEdit = (row: number, col: number, dataItem?: ParentChildModel) => {
        const miniPeriodDate = moment(dataItem.extendSubscriptionLeaveDate).format(dateEditorFormat);
        currentEditingRowRef.current = row;
        leaveDateIsEditingRef.current = col == getLeaveDateGracePeriodColumnIndexes().leaveDateColumnIndex;
        gracePeriodEndDateIsEditingRef.current = col == getLeaveDateGracePeriodColumnIndexes().gracePeriodEndDateColumnIndex;
        unitIsEditingRef.current = col == getLeaveDateGracePeriodColumnIndexes().unitColumnIndex;
        needSaveRef.current = false;
        dataItem.dataIsDirty = true;
        leaveDateIsEditingRef.current && setLeaveDateIsEditing(true);
        const currentEditor = wijmoGridRef.current[`currentEditor${col}`];
        if (leaveDateIsEditingRef.current) {
            currentEditor.max = maxLeaveDate;
            currentEditor.isRequired = dataItem.leaveDate != null;
        }
        gracePeriodEndDateIsEditingRef.current && setGracePeriodEndDateIsEditing(true);
        if (gracePeriodEndDateIsEditingRef.current) {
            currentEditor.isRequired = false;
            currentEditor.min = miniPeriodDate;
        }
        unitIsEditingRef.current && setUnitIsEditing(true);
        if (unitIsEditingRef.current) {
            currentEditor.isRequired = true;
            currentEditor.format = 'D';
        }
        wijmoGridRef.current.startEditing(true, row, col, true);
    }

    const endEdit = (needSave: boolean, row: number, col?: number, dataItem?: ParentChildModel) => {
        const data = dataItem ? dataItem : (wijmoGridRef.current && wijmoGridRef.current.rows.length > 0 && row >= 0 ? wijmoGridRef.current.rows[row].dataItem : {});
        const { leaveDateColumnIndex, gracePeriodEndDateColumnIndex, unitColumnIndex } = getLeaveDateGracePeriodColumnIndexes();
        const currentEditor = wijmoGridRef.current[`currentEditor${col}`];
        if (needSave && col == unitColumnIndex && currentEditor && (currentEditor['value'] < 1 || currentEditor['value'] > dataItem.schoolMaxUnit)) {
            MessageHelper.Message(NotificationType.Warning, fmtMsg({ id: GSAdminLocale.SupportStudentMaxUnitValidationError }, { schoolMaxUnit: dataItem.schoolMaxUnit }));
            return;
        }
        data.dataIsDirty = false;
        currentEditingRowRef.current = defaultEditingRow;
        leaveDateIsEditingRef.current = false;
        gracePeriodEndDateIsEditingRef.current = false;
        unitIsEditingRef.current = false;
        needSaveRef.current = needSave;
        if (!currentEditor) return;
        const setEditingState = () => {
            currentEditor['_isEditing'] = false;
            wijmoGridRef.current.finishEditing();
            currentEditor['_commitRowEdits'] && currentEditor['_commitRowEdits']();
            col == leaveDateColumnIndex && setLeaveDateIsEditing(false);
            col == gracePeriodEndDateColumnIndex && setGracePeriodEndDateIsEditing(false);
            col == unitColumnIndex && setUnitIsEditing(false);
        }
        const removeEditor = () => {
            const host = currentEditor.hostElement;
            const hostParent = host.parentElement;
            if (!hostParent) return;
            hostParent.removeChild(host);
        }
        if (needSave) {
            const editorValue = col == unitColumnIndex ? currentEditor['value'] : DateHelper.formatDate2Local(currentEditor['value']);
            const updateValue = col == unitColumnIndex ? currentEditor['value'] : DateHelper.toUTCString(editorValue);
            let promise = null;
            switch (col) {
                case leaveDateColumnIndex:
                    promise = studentService.updateLeaveDate(dataItem.id, dataItem.schoolClassId, editorValue ? updateValue : null);
                    break;
                case unitColumnIndex:
                    promise = studentService.updateMaxUnit(dataItem.id, updateValue);
                    break;
                case gracePeriodEndDateColumnIndex:
                default:
                    promise = studentService.updateStudentSubscriptionEndDate(dataItem.id, updateValue);
                    break;
            }
            promise.then(result => {
                wijmoGridRef.current.setCellData(row, col, editorValue);
                removeEditor();
                data.leaveDateOrigin = data.leaveDate;
                data.gracePeriodEndDateOrigin = data.gracePeriodEndDate;
                wijmoGridRef.current.invalidate();
                setEditingState();
            }).catch(er => {
            });
        }
        else {
            data.leaveDate = data.leaveDateOrigin;
            data.gracePeriodEndDate = data.gracePeriodEndDateOrigin;
            wijmoGridRef.current.invalidate();
            removeEditor();
            setEditingState();
        }
    }

    const isCellReadOnly = (s, e) => {
        return (s.columns[e.col].binding != gracePeriodEndDateColumnName
                && s.columns[e.col].binding != gracePeriodEndDateActionColumnName)
            || !gracePeriodEndDateIsEditingRef.current;
    }

    const hitTestGrid = (event) => {
        if (!wijmoGridRef.current) return {};
        const ht = wijmoGridRef.current.hitTest(event);
        return {
            row: ht.row,
            col: ht.col,
            dataItem: wijmoGridRef.current.rows[ht.row].dataItem
        }
    }

    const getDateFormatTemplate = () => {
        return LanguageDateFormat[GLGlobal.intl.locale].replace(/D/g, 'd').replace(/Y/g, 'y')
    }

    const initCustomGridEditors = (grid: FlexGrid) => {
        new CustomGridEditor(grid,
            gracePeriodEndDateColumnName,
            gracePeriodEndDateActionColumnName,
            wjcInput.InputDate,
            {
                format: getDateFormatTemplate(),
                min: miniPeriodDate,
                isRequired: false,
                dropDownCssClass: "grace-period-end-date-grid-calendar",
            }, {
                isCellReadOnly: (s, e) => isCellReadOnly(s, e),
                onEnter: onEditorEnter(gracePeriodEndDateColumnName)
            });
        new CustomGridEditor(grid,
            leaveDateColumnName,
            leaveDateActionColumnName,
            wjcInput.InputDate,
            {
                format: getDateFormatTemplate(),
                max: maxLeaveDate,
                isRequired: false,
                dropDownCssClass: "leave-date-grid-calendar"
            }, {
                isCellReadOnly: (s, e) => isCellReadOnly(s, e),
                onEnter: onEditorEnter(leaveDateColumnName)
            });
        new CustomGridEditor(grid,
            unitColumnName,
            unitActionColumnName,
            wjcInput.InputNumber,
            {
                isRequired: true,
                format: 'D'
            }, {
                isCellReadOnly: (s, e) => isCellReadOnly(s, e),
                onEnter: onEditorEnter(unitColumnName)
            });
    }

    const onEditorEnter = (columnName: string) => {
        return () => {
            const row = currentEditingRowRef.current;
            const dataItem = wijmoGridRef.current.rows[row].dataItem;
            const column = getColumn(columnName);
            endEdit(true, row, column.index, dataItem);
        }
    }

    const getEditActionColumnIndexes = () => {
        return {
            leaveDateActionColumnIndex: wijmoGridRef.current.columns.indexOf(leaveDateActionColumnName),
            gracePeriodEndDateActionColumnIndex: wijmoGridRef.current.columns.indexOf(gracePeriodEndDateActionColumnName),
            unitActionColumnIndex: wijmoGridRef.current.columns.indexOf(unitActionColumnName)
        }
    }

    const getLeaveDateGracePeriodColumnIndexes = () => {
        return {
            leaveDateColumnIndex: wijmoGridRef.current.columns.indexOf(leaveDateColumnName),
            gracePeriodEndDateColumnIndex: wijmoGridRef.current.columns.indexOf(gracePeriodEndDateColumnName),
            unitColumnIndex: wijmoGridRef.current.columns.indexOf(unitColumnName)
        }
    }

    const getColumn = (columnName: string) => {
        return wijmoGridRef.current.columns[wijmoGridRef.current.columns.indexOf(columnName)];
    }

    const isLeaveDateReadOnly = (dataItem: ParentChildModel) => {
        return (!dataItem.isDormant && !dataItem.schoolDisabled) || !dataItem.schoolClassId;
    }

    const isGracePeriodEndDateReadOnly = (dataItem: ParentChildModel) => {
        return !dataItem.extendSubscriptionLeaveDate;
    }

    const isUnitReadOnly = (dataItem: ParentChildModel) => {
        return dataItem.unit === null;
    }

    const renderCellEditAction = (row: number, col: number, dataItem: ParentChildModel) => {
        const { leaveDateActionColumnIndex, gracePeriodEndDateActionColumnIndex, unitActionColumnIndex } = getEditActionColumnIndexes();
        if (col == leaveDateActionColumnIndex && isLeaveDateReadOnly(dataItem)) return "";
        if (col == gracePeriodEndDateActionColumnIndex && isGracePeriodEndDateReadOnly(dataItem)) return "";
        if (col == unitActionColumnIndex && isUnitReadOnly(dataItem)) return "";
        const isEditing = (col == gracePeriodEndDateActionColumnIndex && gracePeriodEndDateIsEditingRef.current)
            || (col == leaveDateActionColumnIndex && leaveDateIsEditingRef.current)
            || (col == unitActionColumnIndex && unitIsEditingRef.current);
        let className = ""
        switch (col) {
            case leaveDateActionColumnIndex:
                className = "leave-date";
                break;
            case gracePeriodEndDateActionColumnIndex:
                className = "gracePeriod-end-date";
                break;
        }
        if (isEditing && row == currentEditingRowRef.current) {
            return `<a class="${className} ok"><i aria-label="icon: check" class="anticon anticon-check">${okSVG}</i></a><a class="${className} cancel"><i aria-label="icon: close" class="anticon anticon-close">${cancelSVG}</i></a>`
        }
        else {
            return `<a class="${className} edit"><i aria-label="icon: edit" class="anticon anticon-edit">${editSVG}</i></a>`;
        }
    }

    const getEventSrcElement = (event) => {
        return event.srcElement.ownerSVGElement ? event.srcElement.ownerSVGElement.outerHTML : event.srcElement.outerHTML
    }

    const handleEditableColumnAction = (event) => {
        const { leaveDateActionColumnIndex, gracePeriodEndDateActionColumnIndex, unitActionColumnIndex } = getEditActionColumnIndexes();
        const leaveDateColumn = getColumn(leaveDateColumnName);
        const gracePeriodEndDateColumn = getColumn(gracePeriodEndDateColumnName);
        const unitColumn = getColumn(unitColumnName);
        const { row, col, dataItem } = hitTestGrid(event);
        let editingCol = null;
        switch (col) {
            case leaveDateActionColumnIndex:
                editingCol = leaveDateColumn.index;
                break;
            case unitActionColumnIndex:
                editingCol = unitColumn.index;
                break;
            case gracePeriodEndDateActionColumnIndex:
            default:
                editingCol = gracePeriodEndDateColumn.index;
                break;
        }
        const target = getEventSrcElement(event);
        switch (target) {
            case okButton:
            case okSVG:
                event.preventDefault();
                endEdit(true, row, editingCol, dataItem);
                break;
            case cancelButton:
            case cancelSVG:
                event.preventDefault();
                endEdit(false, row, editingCol, dataItem);
                break;
            case editButton:
            case editSVG:
                event.preventDefault();
                startEdit(row, editingCol, dataItem);
                break;
            default:
                return;
        }
    }

    const selectNeedfulData = (dataItem: ParentChildModel) => {
        const { regionId, schoolId, id, schoolClassId, campusId } = dataItem;
        return { regionId, schoolId, campusId, childId: id, classId: schoolClassId };
    }

    const formatItem = (s, e) => {
        if (e.panel.cellType === CellType.Cell && !(s.rows[e.row] instanceof GroupRow)) {
            const dataItem: ParentChildModel = e.panel.rows[e.row].dataItem;
            const dataItemSelective = selectNeedfulData(dataItem);
            const col = s.columns[e.col];
            switch (col.binding) {
                case "name":
                    if (!dataItem.schoolClassId || dataItem.schoolClassId === CONSTS.EmptyGuid) {
                        return e.panel.getCellData(e.row, e.col);
                    }
                    else if (e.panel.rows[e.row].dataItem.isDormant || e.panel.rows[e.row].dataItem.schoolDisabled) {
                        e.cell.innerHTML = getLinkString(dataItemSelective, "<i>" + e.panel.getCellData(e.row, e.col) + "</i>", LinkType.name);
                    }
                    else {
                        e.cell.innerHTML = getLinkString(dataItemSelective, "<span>" + e.panel.getCellData(e.row, e.col) + "</span>", LinkType.name);
                    }
                    break;
                case "regionName":
                    e.cell.innerHTML = getLinkString(dataItemSelective, e.cell.innerHTML, LinkType.region);
                    break;
                case "schoolName":
                    e.cell.innerHTML = getLinkString(dataItemSelective, e.cell.innerHTML, LinkType.school);
                    break;
                case "campusName":
                    e.cell.innerHTML = getLinkString(dataItemSelective, e.cell.innerHTML, LinkType.campus);
                    break;
                case "schoolClassName":
                    e.cell.innerHTML = getLinkString(dataItemSelective, e.cell.innerHTML, LinkType.class)
                    break;
                case "remove":
                    e.cell.innerHTML = renderUnlinkChild();
                    e.cell.onclick = () => unlinkChild(dataItemSelective.childId, dataItem.name);
                    break;
                case gracePeriodEndDateColumnName:
                    e.cell.innerHTML = DateHelper.formatDate2Local(dataItem.gracePeriodEndDate);
                    break;
                case gracePeriodEndDateActionColumnName:
                    e.cell.innerHTML = renderCellEditAction(e.row, e.col, dataItem);
                    break;
                case leaveDateColumnName:
                    e.cell.innerHTML = DateHelper.formatDate2Local(dataItem.leaveDate);
                    break;
                case leaveDateActionColumnName:
                    e.cell.innerHTML = renderCellEditAction(e.row, e.col, dataItem);
                    break;
                case unitColumnName:
                    e.cell.innerHTML = dataItem.unit;
                    break;
                case unitActionColumnName:
                    e.cell.innerHTML = renderCellEditAction(e.row, e.col, dataItem);
                    break;
                case "registrationDate":
                default:
                    break;
            }
        }
    }

    const getHref = (dataItem: { regionId: string, schoolId: string, campusId: string, classId: string, childId: string, type: LinkType }): string => {
        const { regionId, schoolId, campusId, classId, childId: studentId, type } = dataItem;
        let path = "";
        switch (type) {
            case LinkType.region:
                path = GLUtil.pathStringify(PathConfig.Region, { regionId });
                break;
            case LinkType.school:
                path = GLUtil.pathStringify(PathConfig.Schools, { regionId, schoolId });
                break;
            case LinkType.campus:
                path = GLUtil.pathStringify(PathConfig.Classes, { regionId, schoolId, campusId });
                break;
            case LinkType.class:
                path = GLUtil.pathStringify(PathConfig.Students, { regionId, schoolId, campusId, classId });
                break;
            case LinkType.name:
                path = GLUtil.pathStringify(PathConfig.StudentReport, { regionId, schoolId, campusId, classId, studentId });
                break;
        }
        return path;
    }


    const getCols = () => {
        const cols = [
            <Column
                binding="name"
                header={fmtMsg({
                    id: GSAdminLocale.SupportStudentName
                })}
            />,
            <Column
                binding="regionName"
                header={fmtMsg({
                    id: GSAdminLocale.SupportRegion
                })}
            />,
            <Column
                binding="schoolName"
                header={fmtMsg({
                    id: GSAdminLocale.SupportSchool
                })}
            />,
            <Column
                binding="campusName"
                header={fmtMsg({
                    id: GSAdminLocale.SupportCampus
                })}
            />,
            <Column
                binding="schoolClassName"
                header={fmtMsg({
                    id: GSAdminLocale.SupportClass
                })}
            />,
            <Column
                binding="registrationDate"
                header={fmtMsg({
                    id: GSAdminLocale.SupportStudentRegistrationDate
                })}
            />,
            <Column
                binding={leaveDateColumnName}
                allowMerging={true}
                isReadOnly={!leaveDateIsEditingRef.current}
                header={fmtMsg({
                    id: GSAdminLocale.SupportStudentLeaveDate
                })}
            />,
        ];
        if (GLGlobal.isActionValid(GSAdminAction.EditExtendedSubscription)) {
            cols.push(
                <Column
                    binding={leaveDateActionColumnName}
                    allowMerging={true}
                    header={fmtMsg({
                        id: GSAdminLocale.SupportStudentLeaveDate
                    })}
                    allowSorting={false}
                />
            );
        }
        cols.push(
            <Column
                binding={gracePeriodEndDateColumnName}
                allowMerging={true}
                isReadOnly={!gracePeriodEndDateIsEditingRef.current}
                header={fmtMsg({
                    id: GSAdminLocale.SupportStudentGracePeriodEndingDate
                })}
            />
        );
        if (GLGlobal.isActionValid(GSAdminAction.EditExtendedSubscription)) {
            cols.push(
                <Column
                    binding={gracePeriodEndDateActionColumnName}
                    allowMerging={true}
                    header={fmtMsg({
                        id: GSAdminLocale.SupportStudentGracePeriodEndingDate
                    })}
                    allowSorting={false}
                />
            );
        }
        cols.push(
            <Column
                binding={unitColumnName}
                allowMerging={true}
                isReadOnly={!unitIsEditingRef.current}
                header={fmtMsg({
                    id: GSAdminLocale.SupportStudentMaxUnit
                })}
                align={"left"}
            />
        );
        if (GLGlobal.isActionValid(GSAdminAction.EditExtendedSubscription)) {
            cols.push(
                <Column
                    binding={unitActionColumnName}
                    allowMerging={true}
                    header={fmtMsg({
                        id: GSAdminLocale.SupportStudentMaxUnit
                    })}
                    allowSorting={false}
                    align={"left"}
                />
            );
        }
        // add remove cols to users which are not only of School admin, campus admin and teacher
        const userInfo = ContextHelper.getUserLoginInfo();
        const roles = userInfo && userInfo.profile && userInfo.profile.roleInfos ? userInfo.profile.roleInfos : null;
        const allowedUnlinkRoles = ["RegionAdmin", "TrainingAdmin", "GlobalHead", "SystemAdmin", "AccountManager"];
        const isAllowed = roles.some(r => allowedUnlinkRoles.includes(r.name));
        if (isAllowed) {
            cols.push(
                <Column
                    binding="remove"
                    header=" "
                    allowSorting={false}
                    width={30}
                />);
        }
        return cols;
    }

    const renderChildrenList = () => {
        const fmtMsg = GLGlobal.intl.formatMessage;
        const groupBarText = fmtMsg({
            id: GSAdminLocale.SupportWijmoDragInfo
        });
        return (
            <>{props.parentLoading ? null :
                <>
                    <PageHeader title={fmtMsg({ id: SchoolLocale.Parent })} materialIcon="perm_identity" />
                    <Grid
                        isReadOnly={false}
                        className="student-list"
                        initialized={initGrid}
                        allowMerging={AllowMerging.ColumnHeaders}
                        allowSorting={!gracePeriodEndDateIsEditingRef.current}
                        selectionMode={SelectionMode.ListBox}
                        itemsSource={wijmoData}
                        groupBarText={groupBarText}
                        loading={loading}
                        formatItem={formatItem}
                    >
                        {getCols()}
                    </Grid>
                </>
            }
            </>
        );
    };
    return renderChildrenList();
};

export const ChildrenList = withRouter(ChildrenListComponent);


ChildrenList.defaultProps = {
    isFromEditUser: false
};
