import * as React from "react";
import { GLGlobal, withRouter, GLRouteComponentProps, RoleName } from "gl-commonui";
import {CellType, GroupRow, SelectionMode} from "wijmo/wijmo.grid";
import { message, Modal } from "antd-min";
import { DeviceModel, IDeviceService, DeviceResponseModel } from "@app/service/school/device";
import { TYPES, DateHelper, ContextHelper, fmtMsg } from "@app/util/index";
import { GSAdminLocale } from "@app/locales/localeid";
import { useService } from "@app/hooks";
import { PageHeader, Loading } from "@app/components";
import { WijmoGrid } from "@app/components/grid/wijmo-grid";
import { CloseIcon } from "../../permissions/component/helper";
import { Column } from "@app/components/grid/grid-column";
import _ from "lodash";

const AppIdName: { [key: number]: string } = {
    1: "GrapeSEED Student App",
    2: "LittleSEED Student App"
}

interface UserDeviceListProps extends GLRouteComponentProps {
    userId: string;
}

const UserDeviceListComponent = (props: UserDeviceListProps) => {
    const [defaultCount, setDefaultCount] = React.useState<number>(0);
    const deviceService = useService<IDeviceService>(TYPES.IDeviceService);
    const [loading, setLoading] = React.useState(true);
    const [deviceList, setDeviceList] = React.useState([] as DeviceModel[]);

    const pageSize = 10;

    React.useEffect(() => {
        loadDevices();
        getDeactivatedCount();
        window["removeDevice"] = removeDevice
        return () => delete window["removeDevice"];
    }, []);

    const loadDevices = () => {
        deviceService
            .getDevices({ userId: props.userId })
            .then(data => {
                data && data.devices && data.devices.forEach((data: DeviceModel) => data = formatData(data));
                setDeviceList(data.devices);
                setLoading(false);
            })
            .catch(() => {
                setLoading(false);
            });
    };

    const formatData = (device: DeviceModel) => {
        device.lastLoginDate = DateHelper.formatDate2Local(device.lastLoginDate);
        device.formattedAppId = (device.appId != null) ? AppIdName[device.appId] : "NA";
        device.appVersion = (device.appVersion != null) ? device.appVersion: "NA";
        return device;
    }

    const getDeactivatedCount = () => {
        deviceService.getDeactivationCount({ userId: props.userId }).then(deactivatedCount => {
            setDefaultCount(_.size(deactivatedCount));
        });
    };

    const removeDevice = (deviceId: string) => {
        const deactivate = () => deviceService.deactivateDevice({ deviceId })
            .then(deactivated => {
                if (deactivated) {
                    message.success(fmtMsg({ id: GSAdminLocale.DeviceDeactivationSuccess }));
                    loadDevices();
                    getDeactivatedCount();
                }
                else {
                    message.error(fmtMsg({ id: GSAdminLocale.DeviceDeactivationFailed }));
                }
            })

        Modal.confirm({
            onOk: deactivate,
            title: fmtMsg({ id: GSAdminLocale.UsersRemoveResource }),
            content: fmtMsg({ id: GSAdminLocale.DeviceDeactivationConfirm }, { resource: name }),
            okText: fmtMsg({ id: GSAdminLocale.ButtonOk }),
            cancelText: fmtMsg({ id: GSAdminLocale.ButtonCancel }),
        })
    }

    const getColumns = () => {
        var cols = [{
            localeId: GSAdminLocale.SupportDeviceName,
            dataIndex: "deviceName",
            width: "40*",
            allowDragging: false
        },
        {
            localeId: GSAdminLocale.SupportDeviceModel,
            dataIndex: "model",
            width: "15*",
            allowDragging: false
        },
        {
            localeId: GSAdminLocale.SupportDeviceBrand,
            dataIndex: "deviceBrand",
            width: "15*",
            allowDragging: false
        },
        {
            localeId: GSAdminLocale.SupportDeviceAppName,
            dataIndex: "formattedAppId",
            width: "15*",
            allowDragging: false
        },
        {
            localeId: GSAdminLocale.SupportDeviceAppVersion,
            dataIndex: "appVersion",
            width: "15*",
            allowDragging: false
        },
        {
            localeId: GSAdminLocale.SupportDeviceVersion,
            dataIndex: "systemVersion",
            width: "15*",
            allowDragging: false
        },
        {
            localeId: GSAdminLocale.SupportDeviceDate,
            dataIndex: "lastLoginDate",
            width: "15*",
            allowDragging: false
        }];
        if (canRemoveDevice()) {
            cols.push({
                localeId: "",
                dataIndex: "remove",
                width: 80,
                allowDragging: false
            })
        }
        return cols;
    }

    const canRemoveDevice = (): boolean => {
        return ContextHelper.isUserMatchRole(RoleName.regionAdmin) ||
            ContextHelper.isUserMatchRole(RoleName.globalHead) ||
            ContextHelper.isUserMatchRole(RoleName.systemAdmin)
    }

    const formatItem = (s, e) => {
        if (e.panel.cellType === CellType.Cell && !(s.rows[e.row] instanceof GroupRow)) {
            if (s.columns[e.col].binding === "remove") {
                e.cell.innerHTML = `<span class="remove_icon">
                <span class="remove_icon--pointer" onclick="(function(){
                    window.removeDevice('${s.rows[e.row].dataItem.id}');
                })()">${CloseIcon}</span></span>`
            }
        }
    }

    const renderDeviceList = () => {
        const fmtMsg = GLGlobal.intl.formatMessage;
        return (
            <div className="devicelist">
                <PageHeader
                    title={fmtMsg({ id: GSAdminLocale.SupportDeviceList })}
                    subtitle={fmtMsg(
                        {
                            id: GSAdminLocale.SupportDeviceDeactivatedCount
                        },
                        { count: defaultCount }
                    )}
                    materialIcon="devices_other"
                />
                {loading ? <Loading visible={loading} />
                    : <WijmoGrid
                        columnLangMap={getColumns().map(({ localeId, dataIndex }) => ({ localeId, dataIndex }))}
                        pageSize={pageSize}
                        loading={loading}
                        selectionMode={SelectionMode.ListBox}
                        headersVisibility="Column"
                        itemsSource={deviceList}
                        formatItem={formatItem}
                        pagination={true}
                        alternatingRowStep={0}
                        isReadOnly
                    >
                        {getColumns().map((column, index) => <Column
                            key={index}
                            allowDragging={column.allowDragging}
                            binding={column.dataIndex}
                            header={column.localeId.length ? fmtMsg({ id: column.localeId }) : " "}
                            width={column.width} />)}
                    </WijmoGrid>
                }
            </div>
        );
    };
    return renderDeviceList();
};

export const UserDeviceList = withRouter(UserDeviceListComponent);
