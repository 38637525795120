import { GSAdminLocale } from "@app/locales/localeid";
import { Role, ResourceType, RoleName } from "gl-commonui";

export const CloseIcon = `<span aria-label="icon: close-circle" class="anticon anticon-close-circle"><svg viewBox="64 64 896 896" focusable="false" class="" data-icon="close-circle" width="1em" height="1em" fill="currentColor" aria-hidden="true"><path d="M685.4 354.8c0-4.4-3.6-8-8-8l-66 .3L512 465.6l-99.3-118.4-66.1-.3c-4.4 0-8 3.5-8 8 0 1.9.7 3.7 1.9 5.2l130.1 155L340.5 670a8.32 8.32 0 0 0-1.9 5.2c0 4.4 3.6 8 8 8l66.1-.3L512 564.4l99.3 118.4 66 .3c4.4 0 8-3.5 8-8 0-1.9-.7-3.7-1.9-5.2L553.5 515l130.1-155c1.2-1.4 1.8-3.3 1.8-5.2z"></path><path d="M512 65C264.6 65 64 265.6 64 513s200.6 448 448 448 448-200.6 448-448S759.4 65 512 65zm0 820c-205.4 0-372-166.6-372-372s166.6-372 372-372 372 166.6 372 372-166.6 372-372 372z"></path></svg></span>`;
export const flexGridColumns = {
    systemAdmin: [
        {
            localeId: GSAdminLocale.SupportRegion,
            dataIndex: "regionEnglishName",
            width: "100*",
            allowDragging: false
        }
    ],
    regionAdmin: [
        {
            localeId: GSAdminLocale.SupportRegion,
            dataIndex: "regionEnglishName",
            width: "100*",
            allowDragging: false
        }
    ],
    schoolAdminAccountManager: [
        {
            localeId: GSAdminLocale.SupportRegion,
            dataIndex: "regionEnglishName",
            width: "50*",
            allowDragging: false
        },
        {
            localeId: GSAdminLocale.SupportSchool,
            dataIndex: "school",
            width: "50*",
            allowDragging: false
        }
    ],
    campusAdmin: [
        {
            localeId: GSAdminLocale.SupportRegion,
            dataIndex: "regionEnglishName",
            width: "33*",
            allowDragging: false
        },
        {
            localeId: GSAdminLocale.SupportSchool,
            dataIndex: "school",
            width: "33*",
            allowDragging: false
        },
        {
            localeId: GSAdminLocale.SupportCampus,
            dataIndex: "campus",
            width: "34*",
            allowDragging: false
        }
    ],
    teacher: [
        {
            localeId: GSAdminLocale.SupportRegion,
            dataIndex: "regionEnglishName",
            width: "25*",
            allowDragging: false
        },
        {
            localeId: GSAdminLocale.SupportSchool,
            dataIndex: "school",
            width: "25*",
            allowDragging: false
        },
        {
            localeId: GSAdminLocale.SupportCampus,
            dataIndex: "campus",
            width: "25*",
            allowDragging: false
        },
        {
            localeId: GSAdminLocale.SupportClass,
            dataIndex: "schoolClass",
            width: "25*",
            allowDragging: false
        }
    ],
    removeColumn: [
        {
            localeId: "",
            dataIndex: "remove",
            minWidth: 80,
            maxWidth: 80,
            allowSorting:false,
            allowDragging: false,
            render: (
                role: Role,
                resourceId: string,
                name: string,
                type: ResourceType,
                schoolId?: string,
                regionId?: string
            ) => {
                const data = {
                    id: resourceId,
                    name: escape(name),
                    resourceType: type,
                    schoolId: schoolId,
                    regionId: role == Role.Trainer || role == Role.Teacher ? regionId : null
                };
                return `<span style="padding-left: 20px; cursor: pointer;" onclick='(function (){ window.removeUserResource && 
                    window.removeUserResource(${role}, ${JSON.stringify(data)})
                })()'>${CloseIcon}</span>`;
            }
        }
    ]
};
