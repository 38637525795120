import * as React from "react";
import { Link } from "react-router-dom";
import {
    GLGlobal,
    withRouter,
    GLRouteComponentProps,
    Role,
    GLUtil,
    ResourceType,
    RoleName
} from "gl-commonui";
import { WijmoGrid } from "@app/components/grid/wijmo-grid";
import { Column } from "@app/components/grid/grid-column";
import { Action, Loading } from "@app/components";
import {GroupRow, CellType, SelectionMode} from "wijmo/wijmo.grid";
import { PathConfig } from "@app/config/pathconfig";
import { fmtMsg, GSAdminAction, GSSchoolAction } from "@app/util";
import { GSAdminLocale } from "@app/locales/localeid";
import "./school-class-list.less";

interface ExtraInfo {
    role: Role;
    superRoles?: Role[];
    nameIndex: string;
    resourceType: ResourceType;
    resourceIdIndex: string;
    heading: string | string[];
    hideGrid?: boolean;
}

interface SchoolClassListProps extends GLRouteComponentProps {
    user: any;
    list: any[];
    flexGridColumns: any[];
    extra: ExtraInfo;
    loading?: boolean;
    onRoleRemove: (role: Role) => void;
}

interface VariousPathParams {
    regionId?: string;
    schoolId?: string;
    campusId?: string;
    classId?: string;
}

const SchoolClassListComponent = (props: SchoolClassListProps) => {
    const pageSize = 10;

    const formatItem = (s, e) => {
        const { flexGridColumns, extra } = props;
        if (
            e.panel.cellType === CellType.Cell &&
            !(s.rows[e.row] instanceof GroupRow)
        ) {
            const dataItem = e.panel.rows[e.row].dataItem;
            if (s.columns[e.col].binding === "remove") {
                // if schoolClass is not present then resourceIdIndex is schoolId and ResourceType is ResourceType.School
                let resourceType = extra.resourceType;
                let resourceIdIndex = extra.resourceIdIndex;
                let nameIndex = extra.nameIndex;
                if (
                    resourceType === ResourceType.SchoolClass &&
                    dataItem[extra.nameIndex] == null
                ) {
                    resourceType = ResourceType.School;
                    resourceIdIndex = "schoolId";
                    nameIndex = "school";
                }
                e.cell.innerHTML = flexGridColumns[
                    flexGridColumns.length - 1
                ].render(
                    extra.role,
                    dataItem[resourceIdIndex],
                    dataItem[nameIndex],
                    extra.resourceType,
                    dataItem["schoolId"],
                    dataItem["regionId"]
                );
            }
            if (s.columns[e.col].binding === "regionEnglishName") {
                e.cell.innerHTML = `<a href="${getHref({
                    regionId: dataItem.regionId
                })}">${e.cell.innerHTML}</a>`;
            }
            if (s.columns[e.col].binding === "school") {
                e.cell.innerHTML = `<a href="${getHref({
                    regionId: dataItem.regionId,
                    schoolId: dataItem.schoolId
                })}">${e.cell.innerHTML}</a>`;
            }
            if (s.columns[e.col].binding === "campus") {
                e.cell.innerHTML = `<a href="${getHref({
                    regionId: dataItem.regionId,
                    schoolId: dataItem.schoolId,
                    campusId: dataItem.campusId
                })}">${e.cell.innerHTML}</a>`;
            }
            if (s.columns[e.col].binding === "schoolClass") {
                e.cell.innerHTML = `<a href="${getHref({
                    regionId: dataItem.regionId,
                    schoolId: dataItem.schoolId,
                    campusId: dataItem.campusId,
                    classId: dataItem.schoolClassId
                })}">${e.cell.innerHTML}</a>`;
            }
        }
    };

    const getHref = (params: VariousPathParams): string => {
        const numberOfParams = Object.keys(params).length;
        let path = "";
        switch (numberOfParams) {
            case 1:
                path = GLUtil.pathStringify(PathConfig.Region, {
                    regionId: params.regionId
                });
                break;
            case 2:
                path = GLUtil.pathStringify(PathConfig.Schools, {
                    regionId: params.regionId,
                    schoolId: params.schoolId
                });
                break;
            case 3:
                path = GLUtil.pathStringify(PathConfig.Classes, {
                    regionId: params.regionId,
                    schoolId: params.schoolId,
                    campusId: params.campusId
                });
                break;
            case 4:
                path = GLUtil.pathStringify(PathConfig.Students, {
                    regionId: params.regionId,
                    schoolId: params.schoolId,
                    campusId: params.campusId,
                    classId: params.classId
                });
                break;
        }
        return path;
    };

    const getHeadingContent = (headingText: string | string[]) => {
        const {
            extra: { superRoles }
        } = props;
        if (
            Array.isArray(headingText) &&
            headingText.length &&
            Array.isArray(superRoles) &&
            superRoles.length == headingText.length
        ) {
            return (
                <React.Fragment>
                    <i className="material-icons">perm_identity</i>
                    {headingText.map((t, i) => {
                        return (
                            <React.Fragment key={t}>
                                {t}
                                {superRoles[i] !== Role.SystemAdmin && getHeadingRemoveContent(superRoles[i])}
                                {i < headingText.length - 1 && ", "}
                            </React.Fragment>
                        );
                    })}
                </React.Fragment>
            );
        } else {
            return (
                <React.Fragment>
                    <i className="material-icons">perm_identity</i>
                    {headingText}
                </React.Fragment>
            );
        }
    };

    const getHeadingRemoveContent = (role: Role) => {
        const {
            match: {
                params: { userId }
            },
            onRoleRemove
        } = props;
        // only show cross icon when user is not itself
        const loggedInUserProfile = GLGlobal.loginInfo().profile;
        const loggedInUserId = loggedInUserProfile.sub;
        if (userId == loggedInUserId || role === Role.Parent) {
            return null;
        }
        return (
            <Action
                materialIcon="close"
                onClick={() => onRoleRemove(role)}
                disabled={loggedInUserProfile.roles && loggedInUserProfile.roles.length === 1 && loggedInUserProfile.roles[0] === RoleName.globalHead}
                className="page-si__cross"
                tooltip={fmtMsg({ id: GSAdminLocale.UsersRemoveRoleTooltip })}
            />
        );
    };

    const getHeading = (extra: ExtraInfo) => {
        const { userId } = props.match.params;
        const { regionId, schoolId } =
            props.list.length > 0
                ? props.list[0]
                : { regionId: null, schoolId: null };
        const headingContent = getHeadingContent(extra.heading);
        switch (extra.role) {
            case Role.SystemAdmin:
            case Role.GlobalHead:
            case Role.TrainingAdmin:
                return headingContent;
            case Role.Trainer:
                const pathRegionCoach = regionId
                    ? GLUtil.pathStringify(PathConfig.RegionCoach, {
                        regionId,
                        regiontrainerId: userId
                    })
                    : null;
                return pathRegionCoach &&
                    GLGlobal.isActionValid(GSAdminAction.RegionCoachHome) ? (
                    <>
                        <Link
                            className={"navigation-link"}
                            to={pathRegionCoach}
                        >
                            {headingContent}
                        </Link>
                        {getHeadingRemoveContent(Role.Trainer)}
                    </>
                ) : (
                    <>
                        {headingContent} {getHeadingRemoveContent(Role.Trainer)}
                    </>
                );
            case Role.Teacher:
                const pathSchoolTeacher =
                    regionId && schoolId
                        ? GLUtil.pathStringify(PathConfig.SchoolTeacher, {
                            regionId,
                            schoolId,
                            teacherId: userId
                        })
                        : null;
                return pathSchoolTeacher &&
                    GLGlobal.isActionValid(GSSchoolAction.SchoolTeacherHome) ? (
                    <>
                        <Link
                            className={"navigation-link"}
                            to={pathSchoolTeacher}
                        >
                            {headingContent}
                        </Link>
                        {getHeadingRemoveContent(Role.Teacher)}
                    </>
                ) : (
                    <>
                        {headingContent} {getHeadingRemoveContent(Role.Teacher)}
                    </>
                );
            default:
                return (
                    <>
                        {headingContent}{" "}
                        {getHeadingRemoveContent(props.extra.role)}
                    </>
                );
        }
    };

    const renderChildrenList = () => {
        const fmtMsg = GLGlobal.intl.formatMessage;
        const { flexGridColumns, loading, list, extra } = props;
        const enablePaging = list && list.length > pageSize;
        const marginBottom = {
            marginBottom: !enablePaging ? "30px" : "0"
        };
        return (
            <>
                <div className="role">{getHeading(extra)}</div>
                {loading ? (
                    <Loading visible={loading}></Loading>
                ) : (
                    !extra.hideGrid && <div style={marginBottom}>
                        <WijmoGrid
                            columnLangMap={flexGridColumns.map(
                                ({ localeId, dataIndex }) => ({
                                    localeId,
                                    dataIndex
                                })
                            )}
                            pageSize={pageSize}
                            className="resource-list"
                            loading={loading}
                            selectionMode={SelectionMode.ListBox}
                            headersVisibility="Column"
                            itemsSource={list}
                            formatItem={formatItem}
                            pagination={enablePaging}
                            alternatingRowStep={0}
                            isReadOnly
                        >
                            {flexGridColumns.map((column, index) => (
                                <Column
                                    key={index}
                                    allowDragging={column.allowDragging}
                                    allowSorting={typeof column.allowSorting === 'boolean' ? column.allowSorting : true}
                                    binding={column.dataIndex}
                                    header={
                                        column.localeId.length
                                            ? fmtMsg({ id: column.localeId })
                                            : " "
                                    }
                                    width={column.width}
                                    minWidth={column.minWidth}
                                    maxWidth={column.maxWidth}
                                />
                            ))}
                        </WijmoGrid>
                    </div>
                )}
            </>
        );
    };

    return renderChildrenList();
};

export const SchoolClassList = withRouter(SchoolClassListComponent);
