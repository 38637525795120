import * as React from 'react'
import { Component } from 'react'
import { Link, RouteComponentProps } from "react-router-dom";
import {
    CONSTS,
    ContextHelper,
    DateHelper,
    fmtMsg,
    GSAdminAction,
    InvitationType,
    lazyInject,
    TYPES
} from '../../../util/index';
import { IUserService, UserFormModelNames } from '../../../service/users/index';
import {
    FormHelper,
    FormItemsLayout,
    GLAction,
    GLForm,
    GLFormComponentProps,
    GLGlobal,
    GLUtil,
    maskThrottle,
    ResourceOperation,
    ResourceType,
    Role,
    RoleName,
    unmaskThrottle,
    withRouter
} from 'gl-commonui';
import { Checkbox, Col, Input, InputNumber, Modal, Row } from 'antd-min';
import { GSAdminLocale, SchoolLocale } from '@app/locales/localeid';
import { AdminPathConfig, PathConfig } from '@app/config/pathconfig';
import { GetUserByIdResponseModel, IAccountService } from '@app/service/admin/accountservice/index';
import { IResourceService } from '@app/service/resources';
import { setBreadcrumb } from '@app/states/admin/resource';
import { IRoleService } from '@app/service/admin/roles';
import { mergeClasses } from '@app/components/survey/functions';
import { SchoolService } from '@app/service/schools';
import { SchoolClassService } from '@app/service/class';
import { Container, SecondaryTitle, SubmitBtns } from '@app/components';
import { PageTitleDelBtn } from '@app/components/school/school-page-title-btn';
import { UserInfo } from './components/userinfo';
import { IInvitationService } from '@app/service/admin/acceptinvitation';
import { RemoveResourceData, ResourceRemovedCallbackData, UserPermissions, UserSupportResource } from '../permissions/permissions';
import { UserDeviceList } from './components/user-devices-list';
import { RoleHelper } from "@app/util/roleHelper";
import { PathConfig as GLPathConfig } from "gl-commonui";
import { PermissionService } from "@app/service/permission";

import "./users-detail.less";
import { isUndefined } from 'lodash';


const { renderFormItem } = FormHelper;

interface UsersDetailPageProps extends RouteComponentProps<any> { }
interface UsersDetailPageStates {
    loading?: boolean;
    resCmd?: ResourceOperation;
    defaultName?: string;
    defaultDAL?: number;
    defaultDisabled?: boolean;
    defaultAllowedTesting?: boolean;
    userVisible?: boolean;
    addRoleVisible?: boolean;
    userRoles?: string[];
    phone?: string;
    lastRemovedResource?: ResourceRemovedCallbackData;
    disableSaveButton: boolean;
    userResources: any;
    allowToImpersonate: boolean;
    currentUser: GetUserByIdResponseModel;
}
@withRouter
@GLForm.create()
export class UsersDetailPage extends Component<UsersDetailPageProps & GLFormComponentProps, UsersDetailPageStates> {
    @lazyInject(TYPES.IUserService)
    service: IUserService
    @lazyInject(TYPES.IAccountService)
    accountservice: IAccountService
    @lazyInject(TYPES.IResourceService)
    resourceService: IResourceService
    @lazyInject(TYPES.ISchoolService)
    schoolService: SchoolService
    @lazyInject(TYPES.ISchoolClassService)
    schoolClassService: SchoolClassService
    @lazyInject(TYPES.IRoleService)
    roleService: IRoleService
    @lazyInject(TYPES.IInvitationService)
    invitationService: IInvitationService
    userId = GLUtil.pathParse(AdminPathConfig.UserEdit).userId
    editModel = null
    accessResource
    isUpdateRegion = false
    currentRegionId = ""
    private isShowDeviceLimitOverride = false;
    private isDisableChecked = false;
    constructor(props, context) {
        super(props, context);
        const selectedUser = JSON.parse(sessionStorage.getItem("selectedUser") || "{}");
        this.currentRegionId = selectedUser.regionInfo ? selectedUser.regionInfo.id : this.currentRegionId;
        this.state = {
            phone: '',
            disableSaveButton: true,
            userResources: {},
            allowToImpersonate: false,
            currentUser: null
        };
        this.isShowDeviceLimitOverride = ContextHelper.isUserMatchRole(RoleName.systemAdmin) || ContextHelper.isUserMatchRole(RoleName.regionAdmin) || ContextHelper.isUserMatchRole(RoleName.accountManager);
    }
    componentDidMount() {
        //this.isUserAccessible();
        this.setTableLayout();
        this.checkPermissionForImpersonation();
    }

    isRedirectToUserListing = (role, resourceid, isRemoveRegion?: boolean, reloadUser?: boolean) => {
        const pService = new PermissionService();
        this.setTableLoading();
        pService.canEditUser({
            userId: this.userId,
            regionId: this.currentRegionId
        }).then(success => {
            if (!success) {
                this.setTableLoading(false);
                this.props.history.push({ pathname: AdminPathConfig.Users });
            }
            else if (!isUndefined(reloadUser) && reloadUser) {
                this.setTableLayout();
            }
            else {
                this.setTableLoading(false);
                this.updateTableLayout(role, resourceid, isRemoveRegion);
            }
        });
    }

    removeResource = (role: Role, d: RemoveResourceData, gridData: UserSupportResource[], trainingMangerGrid: UserSupportResource[]) => {
        const { id: resourceid, name, resourceType, schoolId, regionId } = d;

        const removeHandle = () => Modal.confirm({
            title: fmtMsg({ id: GSAdminLocale.UsersRemoveResource }),
            content: fmtMsg({ id: GSAdminLocale.UsersRemoveResourceContent }, { resource: name }),
            okText: fmtMsg({ id: GSAdminLocale.ButtonOk }),
            cancelText: fmtMsg({ id: GSAdminLocale.ButtonCancel }),
            onOk: () => {
                if (role === Role.Trainer) {
                    this.removeTrainer(role, schoolId, regionId, gridData, trainingMangerGrid, resourceid);
                }
                else if (role === Role.TrainingManager) {
                    this.removeTrainingManager(role, resourceid);
                }
                else {
                    this.onOK(role, resourceid, regionId)
                }
            }
        });
        if (role == Role.Teacher && resourceType == ResourceType.SchoolClass) {
            maskThrottle();
            const schoolService = new SchoolService();
            schoolService.getSchoolTeacher({ id: schoolId, teacherId: this.userId, hasDisabled: true }).then(teachingClasses => {
                teachingClasses.length == 1 && Modal.confirm({
                    title: fmtMsg({ id: SchoolLocale.SchoolTeacherDeleteModalTitle }),
                    content: fmtMsg({ id: SchoolLocale.ClassTeacherLastDeleteMessage }),
                    okText: fmtMsg({ id: SchoolLocale.ClassTeacherDeleteModalYesText }),
                    cancelText: fmtMsg({ id: SchoolLocale.ClassTeacherDeleteModalNoText }),
                    maskClosable: true,
                    className: "class-teacher-delete-modal",
                    width: 540,
                    onOk: () => this.removeSchoolClassTeacher(role, resourceid, true, schoolId, regionId),    //Remove Completely
                    onCancel: (e) => {                                              //Keep in Teacher List
                        if (typeof e == 'object' && e.triggerCancel) {
                            //do nothing, closed dialog by click [X]
                        }
                        else if (typeof e == 'function') {
                            this.onOK(role, resourceid);
                            e();
                        }
                    }
                });
                teachingClasses.length != 1 && removeHandle();
            });
            unmaskThrottle();
        }
        else if (role == Role.AccountManager) {
            Modal.confirm({
                title: fmtMsg({ id: GSAdminLocale.UsersRemoveResource }),
                content: fmtMsg({ id: GSAdminLocale.UsersRemoveResourceContent }, { resource: name }),
                okText: fmtMsg({ id: GSAdminLocale.ButtonOk }),
                cancelText: fmtMsg({ id: GSAdminLocale.ButtonCancel }),
                onOk: () => this.removeSchoolAccountManager(role, resourceid, this.userId)
            });
        }
        else {
            removeHandle();
        }
    }

    removeRegionTrainer = (role, regionId) => {
        this.removeTrainerAndTrainingManager(role, InvitationType.RegionTrainer, regionId, null, false, true);
    }

    removeTrainingManager = (role, regionId) => {
        this.removeTrainerAndTrainingManager(role, InvitationType.TrainingManager, regionId, null, false, false);
    }

    removeSchoolTrainer = (role, resourceId, regionId, removeCompletely, isRegionRemove) => {
        this.removeTrainerAndTrainingManager(role, InvitationType.Trainer, resourceId, regionId, removeCompletely, isRegionRemove);
    }

    removeTrainerAndTrainingManager = (role, invitationType, resourceId, regionId, isCompleteRemove, isRemoveRegion) => {
        this.resourceService.removeUserResourcesWithRole({ invitationType: invitationType, isCompleteRemove: isCompleteRemove, regionId: regionId }, resourceId, this.userId).then(success => {
            this.checkAccessAndUpdateTableLayout(role, resourceId, isRemoveRegion);
        });
    }

    canDeleteRegionCoach = (): boolean => {
        let roles = GLGlobal.loginInfo().profile.roles;
        return roles && roles.indexOf(RoleName.systemAdmin) > -1
            || roles.indexOf(RoleName.trainingAdmin) > -1
            || roles.indexOf(RoleName.regionAdmin) > -1;
    }

    onOK = (role, resourceid, regionId?: string) => {
        const params = {
            userId: this.userId,
            role: role,
            resourceId: resourceid,
            regionId: regionId
        }
        this.resourceService.deleteResource(params).then(success => {
            this.checkAccessAndUpdateTableLayout(role, resourceid);
        });
    }

    removeTrainer = (role, schoolId, regionId, gridData, trainingMangerGrid, resourceId) => {
        const fmtMsg = GLGlobal.intl.formatMessage;
        if (schoolId && schoolId != CONSTS.EmptyGuid) {
            const isRegionRemove = gridData.filter(t => t.regionId == regionId).length > 1;

            // for sadmin/global head/region admin can ask for completely remove
            if (this.canDeleteRegionCoach()) {
                // check for only one school for this region then ask for completely remove from region coach list also
                const completeRemove = gridData.filter(t => t.regionId == regionId).length == 1;
                const isTrainingManager = completeRemove && trainingMangerGrid.filter(t => t.regionId == regionId).length > 0;
                if (completeRemove) {
                    Modal.confirm({
                        title: fmtMsg({ id: SchoolLocale.RegionTrainerDeleteModelTitle }),
                        content: <span>
                            {fmtMsg({ id: SchoolLocale.SchoolTrainerLastDeleteMessage })}
                            {isTrainingManager && (<><br />
                                <span style={{ fontSize: 10, fontWeight: "bold" }}>
                                    {fmtMsg({ id: SchoolLocale.SchoolTrainerLastDeleteNote })}
                                </span></>)}
                        </span>,
                        okText: fmtMsg({ id: SchoolLocale.SchoolTrainerDeleteModalYesText }),
                        cancelText: fmtMsg({ id: SchoolLocale.SchoolTrainerDeleteModalNoText }),
                        maskClosable: true,
                        className: "class-teacher-delete-modal",
                        width: 540,
                        onOk: () => { //Remove Completely
                            this.removeSchoolTrainer(role, schoolId, regionId, true, true);
                        },
                        onCancel: (e) => { //Keep in Region List
                            if (typeof e == 'object' && e.triggerCancel) {
                                //do nothing, closed dialog by click [X]
                            }
                            else if (typeof e == 'function') {
                                this.removeSchoolTrainer(role, schoolId, regionId, false, false);
                                e();
                            }
                        }
                    });
                }
                else {
                    this.removeSchoolTrainer(role, resourceId, regionId, false, isRegionRemove);
                }
            }
            else {
                this.removeSchoolTrainer(role, resourceId, regionId, false, true);
            }
        }
        else if (this.canDeleteRegionCoach()) {
            // remove trainer from region level
            this.removeRegionTrainer(role, regionId);
        }
    }

    removeSchoolClassTeacher = (role, resourceid, iscompleteDelete, schoolId, regionId) => {
        this.schoolClassService.deleteTeacherBy(false, iscompleteDelete, { schoolId, id: resourceid, teacherId: this.userId }, regionId).then(success => {
            this.checkAccessAndUpdateTableLayout(role, resourceid);
        });
    }

    removeSchoolAccountManager = (role, resourceid, userId) => {
        this.schoolService.removeSchoolAccountManagers({ id: resourceid, userId }).then(success => {
            this.checkAccessAndUpdateTableLayout(role, resourceid);
        });
    }

    setTableLoading(isLoading: boolean = true) {
        this.setState({ loading: isLoading });
    }

    checkAccessAndUpdateTableLayout = (role, resourceid, isRemoveRegion?: boolean) => {
        this.isRedirectToUserListing(role, resourceid, isRemoveRegion);
    }

    updateTableLayout = (role, resourceid, isRemoveRegion?: boolean) => {
        this.setState({ lastRemovedResource: { role, resourceId: resourceid, isRemoveRegion } });
    }

    deleteUser() {
        this.service.delete(this.userId).then(data => {
            this.backToList();
        }).catch(error => {
            //console.log(error);
        });
        this.setState({ userVisible: false });
    }

    setTableLayout = () => {
        this.setTableLoading();
        this.accountservice.getUserById({ id: this.userId }).then((data) => {
            this.setState({ currentUser: data, userRoles: data.roles });
            if (this.isUpdateRegion) {
                //this.setState({ tableLayout: this.tableLayout });
            } else {
                if (!data.phone) {
                    data.phone = '';
                }
                this.setState({
                    defaultName: data.name,
                    defaultDAL: data.deviceActivationLimit,
                    defaultDisabled: data.disabled,
                    defaultAllowedTesting: data.allowTesting,
                    phone: data.phone
                });
                setBreadcrumb({
                    user: {
                        id: this.props.match.params.userId,
                        name: data.name
                    }
                });
            }
            this.setTableLoading(false);
            //     });
        });
    }

    onChange(e) {
        this.setState({ defaultDisabled: e.target.checked });
        this.isDisableChecked = e.target.checked;
    }

    onAllowedTestingChange(e) {
        this.setState({ defaultAllowedTesting: e.target.checked });
    }

    handleSubmit(e) {
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
            if (!err) {
                const params = {
                    id: this.userId,
                    userName: values.name,
                    deviceActivationLimit: this.isShowDeviceLimitOverride ? values.deviceActivationLimit : this.state.defaultDAL,
                    disabled: this.state.defaultDisabled,
                    phone: values.phone,
                    allowTesting: this.state.defaultAllowedTesting,
                }
                this.service.updateUser(params).then(data => {
                    this.backToList();
                });
            }
        });
    }

    backToList() {
        this.props.history.push({ pathname: AdminPathConfig.Users });
    }

    setUserRoles = (userRoles) => {
        this.setState({ userRoles })
    }

    isReadonly = (): boolean => {
        if (ContextHelper.isUserMatchRole(RoleName.systemAdmin) || ContextHelper.isUserMatchRole(RoleName.regionAdmin) || ContextHelper.isUserMatchRole(RoleName.accountManager)) {
            return false;
        }
        return true;
    }

    onFormChange = () => {
        if (!this.state.disableSaveButton) {
            return;
        }
        this.setState({ disableSaveButton: false });
    }

    getTeacherPath = () => {
        const { userResources } = this.state;
        return GLUtil.pathStringify(PathConfig.SchoolTeacher, {
            regionId: userResources.Teacher[0].regionId,
            schoolId: userResources.Teacher[0].schoolId,
            teacherId: this.userId
        });
    };

    checkPermissionForImpersonation = () => {
        this.service.isAllowedToImpersonate(this.userId).then(allow => {
            this.setState({ allowToImpersonate: allow });
        });
    }

    isUserAccessible = () => {
        this.service.isUserAccessible(this.userId)
            .then(data => {
                if (!data) {
                    this.props.history.push({ pathname: GLPathConfig.AccessDenied })
                }
            });
    }

    reloadUserInfo = () => {
        this.isRedirectToUserListing(null, null, null, true);
    }

    validPhone = (e) => {
        if (e.target.value[0] === "+") {
            e.target.value = '+' + e.target.value.replace(/\D/g, '');
        } else {
            e.target.value = e.target.value.replace(/\D/g, '');
        }
    }

    render() {
        const { userRoles, lastRemovedResource, userResources, currentUser, loading } = this.state;
        const isOnlyContentAdmin = userRoles && userRoles.length == 1 && userRoles.indexOf(RoleName.contentAdmin) > -1;
        const minCount = 1;
        const maxCount = 99;
        const existEmail = this.state.currentUser && this.state.currentUser.email;
        const form = this.props.form;
        const userId = ContextHelper.getUserLoginInfo().profile.sub;
        const isShowDelete = this.userId == userId ? false : true;
        const readOnly = this.isReadonly();
        const hasOnlyParent = userRoles && userRoles.length === 1 && userRoles.indexOf(RoleName.parent) > -1;
        const resourceReadOnly = readOnly || userId == this.userId;
        const showTeacherDetailsLink = userResources.Teacher && userResources.Teacher.length > 0;
        return (
            <>
                <Container fullWidth className="user-details">
                    <GLForm form={form} className="user" onSubmit={this.handleSubmit.bind(this)} onChange={this.onFormChange}>
                        <SecondaryTitle title={GSAdminLocale.UsersEditUser} >
                            {isShowDelete && !readOnly &&
                                <GLAction action={GSAdminAction.DeleteUser}>
                                    <PageTitleDelBtn type="delete" className="delete" labelId={GSAdminLocale.ButtonDelete} deleteTitleId={GSAdminLocale.UsersEditDelete} delete={() => this.deleteUser()} />
                                </GLAction>
                            }
                        </SecondaryTitle>
                        <Row className={mergeClasses("user-search-field", readOnly && "ant-form-item-readonly")} >
                            <FormItemsLayout colTotal={2}>
                                {renderFormItem(form, GSAdminLocale.UserEditName, UserFormModelNames.name,
                                    <Input></Input>, this.state.defaultName, true)}
                                {(this.isShowDeviceLimitOverride || readOnly) && renderFormItem(form, GSAdminLocale.DeviceLimitOverride, UserFormModelNames.deviceActivationLimit,
                                    <InputNumber min={minCount} max={maxCount} onChange={this.onFormChange} />, this.state.defaultDAL)
                                }
                                {this.state.allowToImpersonate &&
                                    <a style={{ marginTop: '-25px', marginBottom: '20px' }}
                                        href={GLGlobal.processEnv().authorityUrl + "/Impersonation/LoginAsUser?userId=" + this.userId +
                                            "&clientId=" + GLGlobal.processEnv().authorityClientId + '&clientUrl=' + GLUtil.getCurrentSiteUrl(false)}>
                                        {fmtMsg({ id: GSAdminLocale.UsersImpersonation })}
                                    </a>}
                            </FormItemsLayout>
                            <FormItemsLayout colTotal={2}>
                                {renderFormItem(form, GSAdminLocale.UserEditPhone, UserFormModelNames.phone, <Input maxLength={20} disabled={!existEmail} onChange={(e) => this.validPhone(e)}></Input>, this.state.phone)}
                            </FormItemsLayout>
                        </Row>
                        <Row className="user-info-field">
                            <UserInfo readonly={readOnly} userId={this.userId} user={currentUser}></UserInfo>
                        </Row>
                        <Row className="creationdaterow">
                            <Col className="createdon" sm={6} md={6} lg={4} xs={24}>{fmtMsg({ id: GSAdminLocale.SupportUserCreatedOn })}</Col>
                            <Col sm={7} md={5} lg={3} xs={24}> {currentUser && currentUser.creationDate ? DateHelper.formatDate2Local(currentUser.creationDate) : fmtMsg({ id: GSAdminLocale.SupportNA })}</Col>
                            <Col sm={11} md={13} lg={15} xs={24} >
                                {showTeacherDetailsLink
                                    && <Link to={this.getTeacherPath()}>
                                        {fmtMsg({ id: GSAdminLocale.SupportUserTeacherDetails })}
                                    </Link>
                                }
                            </Col>
                        </Row>
                        <Row type="flex">
                            {RoleHelper.getMaxRoleOfCurrentUser() === RoleName.systemAdmin && <Col lg={5} md={8} className="allowedtestingcheck mt-24">
                                <Checkbox disabled={readOnly} checked={this.state.defaultAllowedTesting} onChange={(e) => this.onAllowedTestingChange(e)}>{fmtMsg({ id: GSAdminLocale.UsersEditAllowTesting })}</Checkbox>
                            </Col>
                            }
                            <Col lg={5} md={8} className="disabledcheck mt-24">
                                <Checkbox disabled={readOnly} checked={this.state.defaultDisabled} onChange={(e) => this.onChange(e)}>{fmtMsg({ id: GSAdminLocale.UsersEditDisabled })}</Checkbox>
                            </Col>
                        </Row>
                        <Row className="creationdaterow">
                            <Col className="createdon" sm={6} md={6} lg={4} xs={24}>{fmtMsg({ id: SchoolLocale.StudentLastLogin })}</Col>
                            <Col sm={7} md={5} lg={3} xs={24}> {currentUser && currentUser.lastLogin ? DateHelper.formatDate2Local(currentUser.lastLogin) : fmtMsg({ id: GSAdminLocale.SupportNA })}</Col>
                        </Row>
                        {!readOnly && this.state.userRoles && RoleHelper.isCurrentUserRolesGreaterThan(this.state.userRoles) && <div className="btn-save-user"><SubmitBtns isDisabled={this.state.disableSaveButton} /></div>}
                    </GLForm>
                    {
                        <Row className="permissionText">{fmtMsg({ id: GSAdminLocale.UsersEditRolesAndAssociation })}</Row>
                    }
                    {userRoles && userRoles.length > 0
                        && <UserPermissions
                            readonly={resourceReadOnly}
                            userRoles={userRoles}
                            userId={this.userId}
                            updateTableLayout={this.updateTableLayout}
                            removeResource={this.removeResource}
                            resourceRemoved={lastRemovedResource}
                            setResource={(r) => this.setState({ userResources: r })}
                            reloadUserInfo={this.reloadUserInfo}
                        ></UserPermissions>
                    }
                    {
                        (!userRoles || userRoles.length == 0) && !loading && "User don't have any roles."
                    }
                    {
                        !isOnlyContentAdmin && (<Row>
                            <UserDeviceList userId={this.userId} />
                        </Row>)}
                </Container>
            </>
        )
    }
}
