import React from "react";
import { Modal, message, Tooltip } from "antd-min";
import { GSAdminLocale } from "@app/locales/localeid";
import { fmtMsg } from "@app/util";
import { useService } from "@app/hooks";
import { IAccountService } from "@app/service/admin/accountservice";
import { TYPES } from "@app/service/types";

interface ResetPasswordProps {
    userId: string;
}

export const ResetPassword = (props: ResetPasswordProps) => {
    const accountService = useService<IAccountService>(TYPES.IAccountService);
    const { userId } = props;

    const onResetPasswordClick = () => {
        const modal = Modal.confirm({
            title: fmtMsg({ id: GSAdminLocale.ResetPasswordConfirmText }),
            okText: fmtMsg({ id: GSAdminLocale.ButtonOk }),
            cancelText: fmtMsg({ id: GSAdminLocale.ButtonCancel }),
            onOk: () => {
                accountService.resetPassword(userId)
                    .then(() => {
                        message.success(fmtMsg({ id: GSAdminLocale.UserUpdateSuccess }));
                    })
                    .catch(() => {
                        message.error(fmtMsg({ id: GSAdminLocale.UserUpdateError }));
                    });
            },
            onCancel: () => {
                if (modal && modal.destroy) {
                    modal.destroy();
                }
            },
        });
    }

    return (<Tooltip title={fmtMsg({ id: GSAdminLocale.ResetPassword })}>
            <a onClick={onResetPasswordClick}>{fmtMsg({ id: GSAdminLocale.ResetPassword })}</a>
        </Tooltip>
    );
}